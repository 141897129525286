<template>
  <div id="tratamentos" class="tratamento-lista">
    <custom-lista
      v-if="!carregando"
      class="tratamento-lista"
      :titulo="titulo"
      :subtitulo="subtitulo"
      :itens="itensRef"
      width="300"
      height="auto"
    />
  </div>
</template>

<script setup>
const { t, locale } = useI18n();
const { data: tratamentos } = await useFetch("/api/site/tratamentos", {
  headers: { "Content-Language": locale },
});
const carregando = ref(true);
const titulo = t("Conheça nossos tratamentos:");
const subtitulo = t(
  "Dos implantes dentários à limpeza, confira os tratamentos que podem transformar o seu sorriso e descubra qual combina com as suas necessidades."
);
let itensRef = ref([]);

onMounted(() => {
  Object.values(tratamentos.value).forEach((item) => {
    itensRef.value.push({
      imagem: item.imagem,
      titulo: item.nome,
      texto: item.resumo,
      botao: {
        label: t("Saiba mais"),
        url: `/tratamentos/${item.slug}/`,
      },
      class: "tratamento-cartao",
    });
  });
  carregando.value = false;
});
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Tratamentos",
};
</script>
